import React from "react"
import { Link } from "gatsby"


const cadconversion = () => {
    return (
        <>
            <section>
                <div className="parallax">
                    <img src="/img/black-illustration-on-white-printer-paper-716661.jpg" alt="" />
                    <div className="overlay"></div>
                    <div className="headercontent">
                        <h1>CAD CONVERSION</h1>
                    </div>
                </div>
            </section>
            <section className="cad">
                <div className="container pt-4">
                    <div className="row">
                        <div className="col-12 col-lg-12">
                            <h4 className="cadcon">CAD Conversion Services:</h4>
                            <p>Innoway Systems offers CAD conversion services to designers, engineers, independent service providers, consultants, research scholars, freelancers, architectural firms, and engineering firms around the globe. We accept all type of formats from you; we can match all of our mechanical, architectural, civil and structural engineering needs when you outsource CAD conversion to us.</p>
                            <h4 className="cadcon">Our CAD Conversion Services:</h4>
                            <p>When you outsource CAD conversion services to Innoway Systems, you are working with a professional with domain expertise. The team has experience and intelligence to work with a range of different formats and file types. Our services include:</p>

                            <ul>
                                <li>Paper sketch to CAD Conversion</li>
                                <li>Image to CAD Conversion(JPEG/JFIF, TIFF, GIF, BMP, PNG)</li>
                                <li>PDF to CAD Conversion</li>
                                <li>2D to 3D conversion</li>
                                <li>Any file format to desired output</li>

                            </ul>
                            <h4 className="cadcon">Files Delivery:</h4>
                            <p>The final output of contents can be delivered in a variety of formats including:</p>
                            <ul>
                                <li>.DWG</li>
                                <li>.DWFx</li>
                                <li>.DWF</li>
                                <li>.DGN</li>
                                <li>.WRF</li>
                                <li>.ACIS</li>
                                <li>.DWF</li>

                            </ul>
                            <p>Every delivery file passes through gate check points to ensure the required quality and format output.
You are welcome to discuss with our experts about your requirement to get more information about the project for better decision making. We do understand the pressing needs of a customer and do not sell our services, but we guide our clients to decide themselves to select the service. The payment modes are hassle free, we accept swift transfer to our bank or throughPayPal®.</p>
                            <p>Ours service is flexible to meet customer expectations. If you need CAD conversion services for your firm, please feel free to <Link to="/contactus/"> contact us </Link>today for a suitable CAD conversion options.</p>
                            <div className="cadconversion"> <Link to="/contactus/" className="btn btn-danger">Contact Now</Link><br /> <br /></div>
                        </div>
                    </div>
                </div>
            </section>

        </>
    )
}

export default cadconversion
